<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-footer
    hide-header-close
    size="l"
    header-class="my-second-class"
    body-bg-variant="gray-lighten"
    class="m-0"
  >
    <b-row>
      <b-col align="left" class="modal-title-access-code">
        {{ $t('views.client.details.infos.sub-details.access-code') }} {{ customer.accessCode }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3"/>
    <qr-code
      :title="customer.accessCode"
      :text="customer.accessCode"
      size="350"
      id="qrCode-id"
      class="d-flex justify-content-center"
    />
    <div class="d-flex justify-content-center mt-3">
      <d-button
        text="general.actions.export"
        class="d-btn btn d-btn-default font-text-title mr-3"
        icon="fa fa-external-link mr-1"
        @on:button-click="onExport"
      />
      <d-button
        text="general.actions.dismiss"
        class="d-btn btn d-btn-danger font-text-title"
        @on:button-click="$bvModal.hide(modalId)"
      />
    </div>
  </b-modal>
</template>
<script>

export default {
  props: {
    modalId: {
      type: String,
      default: 'default'
    },
    display: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    display: function () {
      this.$bvModal.show(this.modalId);
    }
  },
  methods: {
    onExport() {
      const src = document.querySelector('[id="qrCode-id"] img').src;
      const hiddenElement = document.createElement('a');
      hiddenElement.href = src;
      hiddenElement.target = '_blank';
      hiddenElement.download = `QrCode-${ this.customer.fullName }.png`;
      hiddenElement.click();
    }
  }
}
</script>
<style lang="scss" scoped>

/deep/ .modal {
  padding-top: 40px
}

/deep/ .modal-content {
  border-radius: 8px;
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 10px;
  padding-bottom: 15px;
  border-radius: 8px;
}

@media (min-width: 700px) and (max-width: 800px) {
  /deep/ .modal-dialog {
    min-width: 90%;
  }
}

.modal-title-class {
  text-align: center;
  font: normal normal 500 30px Avenir;
  letter-spacing: 0;
  color: #3C3D43;
  opacity: 1;
}

.modal-title-access-code {
  text-align: center;
  font: normal normal 500 20px Avenir;
  letter-spacing: 0px;
  color: #1f2024;
  opacity: 1;
}

</style>
