<template>
  <div class="container-fluid">
    <b-row v-if="mode === 'create'" class="mt-2 mb-2">
      <b-col class="settings-title">
        {{ $t('views.client.new.form.details.label') }}
      </b-col>
    </b-row>
    <b-row class="pl-0 mt-2">
      <b-col cols="6">
        <b-row>
          <b-col cols="12">
            <label class="field-name-class">
              {{ $t('views.client.new.form.details.access-code') }}
            </label>
          </b-col>
          <b-col class="d-flex" cols="12">
            <d-text-field
              v-model="client.accessCode"
              size="sm"
              class-name="background-light-blue-inputs"
            />
            <span class="ml-2">
              <i
                class="pointer fa-2x icofont icofont-refresh"
                @click="setAccessCode"
              ></i>
            </span>
            <span
              :class="client.accessCode ? 'pointer' : 'disabled'"
              class="ml-2"
            >
              <i
                class="fa-2x icofont icofont-qr-code"
                @click="client.accessCode ? displayQrCode = !displayQrCode : null"
              ></i>
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="6">
        <b-row>
          <b-col cols="12">
            <label class="field-name-class">
              {{ $t('views.client.new.form.details.medical-certif') }}
            </label>
          </b-col>
          <b-col cols="12">
            <select size="sm" v-model="client.medicalCertificate" class="form-control background-light-blue-inputs">
              <option :value="true">{{ $t('components.form.playground.bookingValidationNeeded.enabled') }}</option>
              <option selected :value="false">{{ $t('components.form.playground.bookingValidationNeeded.disabled') }}</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <qr-access-code
      modal-id="custom-client-qr-id"
      :customer="client"
      :display="displayQrCode"
    />
  </div>
</template>
<script>
import QrAccessCode from "@custom/clients/infos/QrAccessCode";

export default {
  data: () => ({
    displayQrCode: false,
  }),
  components: {QrAccessCode},
  props: {
    validation: {
      type: Object,
      default: () => require('@validation/entities/doinsport/Client.json')
    },
    containerClass: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'create'
    },
    client: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setAccessCode()   {
      this.client.accessCode =  (Math.random().toString(20).substring(2, 6) + Math.random().toString(10).substring(2, 6)).toUpperCase();
    }
  },
  created () {
    this.client.medicalCertificate = false;
  }
}
</script>
